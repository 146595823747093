<template>
  <div v-if="genericCards.cardSelected">
    <component :is="genericCards.cards[genericCards.cardSelected]"></component>
    <v-row v-if="genericCards.lengthPages">
      <v-col cols="12">
        <div class="d-flex align-center justify-center text-center">
          <v-pagination
            circle
            :disabled="genericCards.disabledPagination"
            v-model="genericCards.page"
            :length="genericCards.lengthPages"
            :total-visible="genericCards.totalVisible"
          ></v-pagination>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "RenderGenericCards",
  computed: {
    genericCards: {
      get() {
        return this.$store.getters["resCandidatos/getGenericCards"];
      },
    },
  },
};
</script>

<style></style>
