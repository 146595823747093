<template>
  <section>
    <v-row>
      <v-col cols="12">
        <h1 class="text-h4 font-weight-bold mt-6">Oportunidades</h1>
        <h2 class="text-subtitle-1 mt-3">
          Veja as vagas abertas nesta empresa!
        </h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <Filtros :disabledInputs="disabledInputs" class="my-6" />
      </v-col>
      <!-- Aparece enquanto as oportunidades são requisitadas -->
      <template v-if="disabledInputs">
        <v-col v-for="(item, i) in 3" :key="i" cols="12" sm="6">
          <v-skeleton-loader
            class="mb-6"
            type="article, actions"
            elevation="3"
          ></v-skeleton-loader>
        </v-col>
      </template>
      <v-col v-else cols="12">
        <RenderGenericCards v-if="oportunidades.data.length > 0" />

        <h2
          v-if="disabledInputs == false && oportunidades.data.length == 0"
          class="text-center mt-6"
        >
          Nenhuma oportunidade encontrada!
        </h2>
      </v-col>
    </v-row>
  </section>
</template>

<script>
import Filtros from "../components/Filtros.vue";
import RenderGenericCards from "../../components/RenderGenericCards.vue";

export default {
  name: "OportunidadeView",
  components: {
    Filtros,
    RenderGenericCards,
  },
  data() {
    return {
      disabledInputs: true,
    };
  },
  created() {
    this.$store.dispatch("resCandidatos/setCardSelected", "oportunidade");

    const subdomain = window.location.host.split(".")[0];

    const url = `/ext/empresas/get-vagas-by-slug/${subdomain}`;

    this.$store
      .dispatch("resCandidatos/getOportunidades", { url })
      .then((response) => {
        if (response) {
          this.genericCards.lengthPages = response.last_page;
          this.genericCards.disabledPagination = false;

          this.disabledInputs = false;
        }
      });
  },
  computed: {
    genericCards: {
      get() {
        return this.$store.getters["resCandidatos/getGenericCards"];
      },
    },
    oportunidades: {
      get() {
        return this.$store.getters["resCandidatos/getOportunidades"];
      },
    },
  },
};
</script>

<style></style>
