<template>
  <v-card class="px-3">
    <v-row>
      <v-col cols="12" sm="6" md="6">
        <v-text-field
          :disabled="disabledInputs"
          v-model="validate.pesquisa"
          hide-details
          label="Pesquisar"
          dense
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="6">
        <div class="d-flex justify-center justify-sm-end">
          <v-btn
            @click="filterVagas()"
            :loading="loadings.loadingButton"
            :disabled="disabledInputs"
            color="info"
          >
            <v-icon class="mr-2">mdi-magnify</v-icon> Pesquisar
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <div class="d-flex flex-row align-center">
          <v-btn
            @click="showOptionsFilter = !showOptionsFilter"
            text
            small
            depressed
            class="mr-2 pl-0 pr-2"
          >
            <v-icon>{{
              showOptionsFilter ? "mdi-chevron-down" : "mdi-chevron-right"
            }}</v-icon>
            <span>Opções de pesquisa</span>
          </v-btn>
          <v-divider />
        </div>
      </v-col>
    </v-row>
    <v-row v-if="showOptionsFilter" justify="center" align="center">
      <v-col cols="12" sm="6" md="6" lg="3">
        <v-select
          :loading="loadings.categoria"
          :disabled="disabledInputs"
          :items="categorias"
          v-model="validate.categoria"
          item-text="descricao"
          item-value="id"
          :hide-details="validate.estado == null ? false : true"
          label="Categoria"
          no-data-text="Nenhuma categoria encontrada"
          dense
          outlined
          clearable
        ></v-select>
      </v-col>
      <v-col cols="12" sm="6" md="6" lg="3">
        <v-select
          :loading="loadings.estado"
          :disabled="disabledInputs"
          :items="estados"
          v-model="validate.estado"
          item-text="nome"
          item-value="id"
          :hide-details="validate.estado == null ? false : true"
          label="Estados"
          no-data-text="Nenhum estado encontrado"
          dense
          outlined
          clearable
        >
          <template v-slot:item="{ item }">
            <span>
              <strong>{{ item.uf }}</strong> {{ item.uf ? "-" : "" }}
              {{ item.nome }}
            </span>
          </template>
        </v-select>
      </v-col>
      <v-col cols="12" sm="6" md="6" lg="3">
        <v-autocomplete
          :loading="loadings.cidade"
          :disabled="validate.estado == null ? true : disabledInputs"
          :items="renderCidadeByEstado"
          v-model="validate.cidade"
          item-text="nome"
          item-value="id"
          :hide-details="validate.estado == null ? false : true"
          messages="Necessário haver algum estado selecionado."
          label="Cidade"
          no-data-text="Nenhuma cidade encontrada"
          placeholder="Digite o nome da cidade"
          dense
          outlined
          clearable
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" sm="6" md="6" lg="3">
        <v-select
          :loading="loadings.tipoVaga"
          :disabled="disabledInputs"
          :items="tipoVagas"
          v-model="validate.tipoVaga"
          item-text="descricao"
          item-value="id"
          :hide-details="validate.estado == null ? false : true"
          label="Status de Trabalho"
          no-data-text="Nenhum status de trabalho encontrado"
          dense
          outlined
          clearable
        ></v-select>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  name: "Filtros",
  props: {
    disabledInputs: {
      type: Boolean,
      default: false,
      require: false,
    },
  },
  data: () => ({
    validate: {
      categoria: null,
      estado: null,
      cidade: null,
      tipoVaga: null,
      pesquisa: "",
    },

    loadings: {
      categoria: true,
      estado: true,
      cidade: true,
      tipoVaga: true,

      loadingButton: false,
    },

    showOptionsFilter: false,
  }),
  async created() {
    const estados = localStorage.getItem("estados");
    const cidades = localStorage.getItem("cidades");

    await this.$store.dispatch("resCategorias/getDataForFilter");
    this.loadings.categoria = false;

    if (estados == null) {
      await this.$store.dispatch("estados/getDataForFilter");
      localStorage.setItem("estados", JSON.stringify(this.estados));
    } else {
      this.estados = JSON.parse(estados);
    }
    this.loadings.estado = false;

    if (cidades == null) {
      await this.$store.dispatch("cidades/getDataForFilter");
      localStorage.setItem("cidades", JSON.stringify(this.cidades));
    } else {
      this.cidades = JSON.parse(cidades);
    }
    this.loadings.cidade = false;

    await this.$store.dispatch("resTipoVagas/getDataForFilter");
    this.loadings.tipoVaga = false;
  },
  methods: {
    filterVagas() {
      this.loadings.loadingButton = true;

      const subdomain = window.location.host.split(".")[0];

      const url = `/ext/empresas/get-vagas-by-slug/${subdomain}`;

      const data = {
        url: url,
        data: {
          validate: this.validate,
        },
      };

      this.$store
        .dispatch("resCandidatos/getOportunidades", data)
        .then((response) => {
          if (response) {
            this.genericCards.lengthPages = response.last_page;
            this.genericCards.disabledPagination = false;
          }
        })
        .finally(() => {
          this.loadings.loadingButton = false;
        });
    },
  },
  computed: {
    categorias: {
      get() {
        const categorias = this.$store.getters["resCategorias/getCategorias"];
        categorias.unshift({
          id: null,
          descricao: "Nenhuma categoria selecionada",
        });

        return categorias;
      },
    },
    estados: {
      get() {
        const estados = this.$store.getters["estados/getEstados"];
        estados.unshift({ id: null, nome: "Nenhum estado selecionado" });

        return estados;
      },
      set(value) {
        this.$store.dispatch("estados/setEstados", value);
      },
    },
    cidades: {
      get() {
        return this.$store.getters["cidades/getCidades"];
      },
      set(value) {
        this.$store.dispatch("cidades/setCidades", value);
      },
    },
    tipoVagas: {
      get() {
        const tipoVagas = this.$store.getters["resTipoVagas/getTipoVagas"];
        tipoVagas.unshift({
          id: null,
          descricao: "Nenhum status de trabalho selecionado",
        });

        return tipoVagas;
      },
    },
    renderCidadeByEstado() {
      if (this.validate.estado == null) return [];

      const cidadesSelecionadas = this.cidades.filter(
        (el) => el.id_estado == this.validate.estado
      );

      cidadesSelecionadas.unshift({
        id: null,
        nome: "Nenhuma cidade selecionada",
      });

      return cidadesSelecionadas;
    },

    genericCards: {
      get() {
        return this.$store.getters["resCandidatos/getGenericCards"];
      },
    },
  },
  watch: {
    "genericCards.page"(now, old) {
      const subdomain = window.location.host.split(".")[0];
      const url = `/ext/empresas/get-vagas-by-slug/${subdomain}?page=${now}`;

      const data = {
        url: url,
        data: {
          validate: this.validate,
        },
      };

      this.$store.dispatch("resCandidatos/getOportunidades", data);
    },
  },
};
</script>
